<template>
  <div class="modal fade" ref="CmpModalFiltrosListadoClientes" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Filtros
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el switch deseado para realizar el filtrado:
          </p>
          
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input 
                  v-model="filtros.activos"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="activos"
                />
                <label class="form-check-label" for="activos">Activos</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input 
                  v-model="filtros.retirados"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="retirados"
                />
                <label class="form-check-label" for="retirados">Retirados</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.suspendidos"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="suspendidos"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="suspendidos">Suspendidos</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.atrasados"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="atrasados"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="atrasados">Atrasados</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.recien_registrados"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="recienRegistrados"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="recienRegistrados">Recién registrados</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.con_facturas_sin_pagar"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="swConFacturasSinPagar"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="swConFacturasSinPagar">Con facturas sin pagar</label>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6" v-show="filtros.con_facturas_sin_pagar">
              <div class="input-group">
                <span class="input-group-text">Desde </span>
                <input type="number" v-model="filtros.facturas_sin_pagar_desde"
                  min="1"
                  class="form-control"
                  oninput="this.value = Math.abs(this.value)"
                  @change="filtrosActualizados()"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6" v-show="filtros.con_facturas_sin_pagar">
              <div class="input-group">
                <span class="input-group-text">Hasta</span>
                <input type="number" v-model="filtros.facturas_sin_pagar_hasta"
                  min="1"
                  placeholder="- - -"
                  class="form-control"
                  @change="
                    facturasSinPagarHasta(),
                    filtrosActualizados()
                  "
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.renta_mensual_fija"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="rentaMensualFija"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="rentaMensualFija">
                  Renta mensual fija
                </label>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  v-model="filtros.renta_mensual_variable"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="rentaMensualVariable"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="rentaMensualVariable">
                  Renta mensual variable
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input 
                  v-model="filtros.sin_contratos"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="contratos"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="contratos">Sin contratos</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input 
                  v-model="filtros.control_manual"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="controlManual"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="controlManual">Control manual</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input 
                  v-model="filtros.control_automatico"
                  @change="filtrosActualizados()"
                  class="form-check-input"
                  type="checkbox"
                  id="controlAutomatico"
                  :disabled="!filtros.activos"
                />
                <label class="form-check-label" for="controlAutomatico">Control automatico</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'CmpModalFiltrosListadoClientes',
  props: {
    valoresInicialesFiltros: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filtros: {
        activos: true,
        suspendidos: false,
        atrasados: false,
        retirados: false,
        recien_registrados: false,
        nuevosAMasAntiguos:false,
        con_facturas_sin_pagar:false,
        facturas_sin_pagar_desde: 1,
        facturas_sin_pagar_hasta: null,
        renta_mensual_fija: false,
        renta_mensual_variable: false,
        sin_contratos: false,
        control_manual: false,
        control_automatico: false
      }
    }
  },

  watch: {
    'filtros.activos': function(newVar, oldVar){
      var self = this
      if(newVar == true){
        self.filtros.suspendidos = false
        self.filtros.atrasados = false
        self.filtros.retirados = false
        self.filtros.recien_registrados = false
        self.filtros.nuevosAMasAntiguos = false
        self.filtros.con_facturas_sin_pagar = false
        self.filtros.facturas_sin_pagar_desde = 1
        self.filtros.facturas_sin_pagar_hasta = null
        self.filtros.renta_mensual_fija = false
        self.filtros.renta_mensual_variable = false
        self.filtros.sin_contratos = false
        self.filtros.control_manual = false
        self.filtros.control_automatico = false
      }
    },
    'filtros.control_manual': function(newVar) {
      var self = this

      if (newVar == false) return

      self.filtros.control_automatico = false
    },

    'filtros.control_automatico': function(newVar) {
      var self = this

      if (newVar == false) return

      self.filtros.control_manual = false
    }
  },

  created: function() {
    var self = this
    // Actualización de los valores iniciales de los filtros
    if (self.valoresInicialesFiltros) {
      self.filtros = self.valoresInicialesFiltros
    }
  },

  methods: {
    facturasSinPagarHasta() {
      var self = this

      if(self.filtros.facturas_sin_pagar_hasta == null || self.filtros.facturas_sin_pagar_hasta == '') return

      if(self.filtros.facturas_sin_pagar_desde > self.filtros.facturas_sin_pagar_hasta)
        self.filtros.facturas_sin_pagar_hasta = self.filtros.facturas_sin_pagar_desde
    },
    filtrosActualizados() {
      this.$emit('filtros-actualizados', this.filtros)
    },
    mostrar() {
      const modal = new Modal(this.$refs.CmpModalFiltrosListadoClientes)
      modal.show()
    }
  },
}
</script>

<style scoped>

</style>
