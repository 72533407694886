<template>
  <Layout :tituloPagina="'Clientes'">
    <div class="row mb-4">
      <div class="col-md-2 col-2 mb-3">
        <button
          class="btn btn-secondary"
          @click="mostrarMdlFiltros()"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-format-list-bulleted"></i>
            Filtros
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-format-list-bulleted"></i>
          </span>
        </button>
      </div>
      <div class="col-md-8 col-8 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarClientes()
          "
          :datoini="dato"
          placeholder="Dato del cliente"
        ></filtrador>
      </div>
      <div class="col-md-2 col-2 mb-3">
        <router-link :to="{ name: 'nuevoCliente' }" class="btn btn-success bx-pull-right">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-8 mb-3">
        <div class="form-check font-size-15" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="contrato"
            v-model="columnas.contacto"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="contrato">Contacto</label>
        </div>
        &nbsp;
        <div class="form-check font-size-15" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="fotoCpe"
            v-model="columnas.foto_cpe"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fotoCpe">Foto de CPE</label>
        </div>
        &nbsp;
        <div class="form-check font-size-15" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="nombreCpe"
            v-model="columnas.nombre_cpe"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="nombreCpe">Nombre de CPE</label>
        </div>
        &nbsp;
        <div class="form-check font-size-15" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="direccionIpCpe"
            v-model="columnas.direccion_ip_cpe"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="direccionIpCpe">Dirección IP</label>
        </div>
        &nbsp;
        <div class="form-check font-size-15" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="servicio"
            v-model="columnas.servicio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="servicio">Servicio</label>
        </div>
      </div>
      <div class="col-md-4 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bx-pull-right bg-gradient font-size-12">
          {{nClientesTotales}} clientes en total
        </span>
        <span class="badge bg-secondary bx-pull-right bg-gradient font-size-12">
          {{nClientesEnPag}} clientes en página
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive" style="min-height: 475px;">
              <table id="tblListaClientes" class="table table-hover table-nowrap">
                <thead class="table-light">
                  <tr>
                    <th style="width: 30px" v-show="foto_cliente">Foto</th>
                    <th class="text-right" style="width: 60px">
                      Número
                      <button class="btn btn-sm"
                        @click="actualizarOrden('numero');" >
                        <i class="mdi"
                          :class="{
                            'mdi-sort': obtenerOrden('numero') == '',
                            'mdi-sort-ascending': obtenerOrden('numero') == 'asc',
                            'mdi-sort-descending':obtenerOrden('numero') == 'desc',
                            'text-gris': $store.state.layout.layoutMode == 'dark'
                          }"></i>
                      </button>
                    </th>
                    <th>
                      Nombre
                      <button class="btn btn-sm"
                        @click="actualizarOrden('nombre_completo');" >
                        <i class="mdi"
                          :class="{
                            'mdi-sort': obtenerOrden('nombre_completo') == '',
                            'mdi-sort-ascending': obtenerOrden('nombre_completo') == 'asc',
                            'mdi-sort-descending':obtenerOrden('nombre_completo') == 'desc',
                            'text-gris': $store.state.layout.layoutMode == 'dark'
                          }"></i>
                      </button>
                    </th>
                    <th v-show="columnas.contacto">Contacto</th>
                    <th v-show="filtros.activos && columnas.foto_cpe">CPE</th>
                    <th v-show="filtros.activos && columnas.nombre_cpe">Nombre de CPE</th>
                    <th v-show="filtros.activos && columnas.direccion_ip_cpe">Dirección IP</th>
                    <th v-show="filtros.activos && columnas.servicio">Servicio</th>
                    <th v-show="filtros.retirados">Municipio</th>
                    <th v-show="filtros.retirados">Fecha de retiro</th>
                    <th v-show="filtros.retirados">Motivo de retiro</th>
                    <th v-show="filtros.con_facturas_sin_pagar"
                      style="width: 120px">
                      Facturas sin pagar
                    </th>
                    <th style="width: 50px" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente in clientes" :key="cliente.id">
                    <td
                      class="text-right td-seleccionable"
                      @click="verTableroCliente(cliente.id)"
                      v-show="foto_cliente"
                    >
                      <img
                        :src="`${API}/clientes/${cliente.id}/foto?_tk=${tk}&foto=64x64`"
                        class="avatar-sm rounded-circle"
                        v-if="cliente.tiene_foto != false"
                      />
                      <img
                        :src="clienteIcon"
                        class="avatar-sm rounded-circle"
                        v-if="cliente.tiene_foto == false"
                      />
                    </td>
                    <td
                      class="text-right td-seleccionable"
                      @click="verTableroCliente(cliente.id)"
                    >
                      <span class="fw-semibold">{{ cliente.numero }}</span>
                    </td>
                    <td
                      class="td-seleccionable"
                      @click="verTableroCliente(cliente.id)"
                    >
                      {{ cliente.nombre }}
                      {{ cliente.apellido_paterno }}
                      {{ cliente.apellido_materno }}
                    </td>
                    <td v-show="columnas.contacto">
                      <button class="btn btn-light btn-outline-success bg-gradient btn-sm"
                        @click="mostrarMdlWhatsapp(cliente.telefonos, cliente.nombre_completo)"
                        v-show="cliente.id != 901 && cliente.id != 902"
                      >
                        <i class="mdi mdi-whatsapp"></i>
                        Whatsapp
                      </button>
                      <button
                        class="btn btn-light btn-sm btn-outline-secondary bg-gradient"
                        name="vcard"
                        title="vCard del cliente"
                        @click="descargarVCard(cliente.id)"
                        v-show="cliente.id != 901 && cliente.id != 902"
                      >
                        <i class="mdi mdi-newspaper-variant-outline"></i>
                      </button>
                    </td>
                    <td
                      class="td-seleccionable"
                      v-show="filtros.activos && columnas.foto_cpe"
                      @click="verTableroCliente(cliente.id)"
                    >
                      <div v-for="contrato in cliente.contratos" :key="contrato.id">
                        <template v-if="contrato.cpe != null">
                          <img
                            :src="`${API}/cpes/modelos/${contrato.cpe.id_modelo}/imagen?tipo_foto=64x64&_tk=${tk}`"
                            class="imgcpes"
                            v-if="contrato.modelo_cpe.id_foto != null && contrato.modelo_cpe.id_foto != 'null'"
                          />
                          <span
                            class="text-success"
                            v-if="contrato.cpe.en_linea == true"
                            title="Conectado"
                          >
                            <i class="fa fa-signal"></i>
                          </span>
                          <span
                            class="text-danger"
                            v-if="contrato.cpe.en_linea == false"
                            title="Desconectado"
                          >
                            <i class="fa fa-exclamation-circle"></i>
                          </span>
                        </template>
                      </div>
                    </td>
                    <td
                      class="td-seleccionable"
                      v-show="filtros.activos && columnas.nombre_cpe"
                      @click="verTableroCliente(cliente.id)"
                    >
                      <div v-for="contrato in cliente.contratos" :key="contrato.id">
                        <template v-if="contrato.cpe != null">
                          {{ contrato.cpe.nombre }}
                        </template>
                      </div>
                    </td>
                    <td
                      class="td-seleccionable"
                      v-show="filtros.activos && columnas.direccion_ip_cpe"
                      @click="verTableroCliente(cliente.id)"
                    >
                      <div v-for="contrato in cliente.contratos" :key="contrato.id">
                        <template v-if="contrato.cpe != null">
                          {{ contrato.cpe.ip_wan}}
                        </template>
                      </div>
                    </td>
                    <td
                      class="td-seleccionable"
                      v-show="filtros.activos && columnas.servicio"
                      @click="verTableroCliente(cliente.id)"
                    >
                      <div v-for="contrato in cliente.contratos" :key="contrato.id">
                        <template v-if="contrato.servicio != null">
                          {{ contrato.servicio.nombre }}
                        </template>

                        <sup
                          class="badge badge-pill badge-soft-info"
                          v-show="contrato.id_tipo_renta == 1"
                          title="Renta Mensual Fija"
                        >
                          RMF
                        </sup>
                        <sup
                          class="badge badge-pill badge-soft-warning"
                          v-show="contrato.id_tipo_renta == 2"
                          title="Renta Mensual Variable"
                        >
                          RMV
                        </sup>
                      </div>
                    </td>
                    <td v-show="filtros.retirados">
                      {{ cliente.municipio }}
                    </td>
                    <td v-show="filtros.retirados">
                      {{ cliente.fecha_retiro }}
                    </td>
                    <td v-show="filtros.retirados">
                      <p class="text-truncate font-size-14" :title="cliente.motivo_retiro">
                        {{ cliente.motivo_retiro }}
                      </p>
                    </td>
                    <td v-show="filtros.con_facturas_sin_pagar">
                      <router-link :to="{ name: 'facturasPendientesPago', params: { id_cliente: cliente.id } }">
                        {{ cliente.cantidad_facturas_sin_pagar}}
                      </router-link>
                    </td>
                    <td class="text-center">
                      <div class="dropdown">
                        <button
                          class="btn btn-light btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-horizontal-rounded"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <router-link :to="{ name: 'tableroCliente', params: { id_cliente: cliente.id } }"
                              class="dropdown-item primary">
                              <i class="mdi mdi-account text-primary"></i>
                              Tablero
                            </router-link>
                          </li>
                          <li v-show="cliente.id != 900 && cliente.id != 901 && cliente.id != 902">
                            <router-link
                              :to="{ name: 'edicionCliente', params: { id_cliente: cliente.id } }"
                              class="dropdown-item warning"
                              v-show="cliente.id != 900 && cliente.id != 901 && cliente.id != 902"
                            >
                              <i class="mdi mdi-content-save-edit-outline text-warning"></i>
                              Editar
                            </router-link>
                          </li>
                          <li v-if="cliente.activo == true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902">
                            <button
                              @click="
                                preguntaRetirarCliente(
                                  cliente.id,
                                  cliente.nombre + ' ' +
                                  cliente.apellido_paterno + ' ' +
                                  cliente.apellido_materno
                                )
                              "
                              v-if="cliente.activo == true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902"
                              class="dropdown-item danger"
                            >
                              <i class="mdi mdi-archive-outline text-danger"></i>
                              Retirar
                            </button>
                          </li>
                          <li v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902">
                            <button
                              class="dropdown-item success"
                              @click="
                                preguntaRestaurarCliente(
                                  cliente.id,
                                  cliente.nombre +
                                  ' ' +
                                  cliente.apellido_paterno +
                                  ' ' +
                                  cliente.apellido_materno
                                )
                              "
                              v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902"
                            >
                              <i class="mdi mdi-check-circle-outline text-success"></i>
                              Restaurar
                            </button>
                          </li>
                          <li v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902">
                            <button
                              class="dropdown-item danger"
                              @click="
                                preguntaBorrarCliente(
                                  cliente.id,
                                  cliente.nombre +
                                  ' ' +
                                  cliente.apellido_paterno +
                                  ' ' +
                                  cliente.apellido_materno
                                )
                              "
                              v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902"
                            >
                              <i class="mdi mdi-trash-can-outline text-danger"></i>
                              Eliminar
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-center">
          <paginador
            :pag="n_pag"
            :totpag="totPag"
            :epp="n_cln_pag"
            v-on:cargar-pagina="cargarPagina"
            v-on:epp-actualizado="
              n_cln_pag = $event,
              refrescarClientes()
            "
          ></paginador>
        </div>
      </div>
    </div>
    <mdl-filtros
      ref="mdlFiltros"
      v-on:filtros-actualizados="actualizarFiltros"
      :valoresInicialesFiltros="filtros"
    ></mdl-filtros>
    <mdl-whatsapp ref="mdlWhatsapp"/>
    <br>
  </Layout>
</template>
  
<script>
import Layout from "@/views/layouts/main.vue"

import API from '@/API.js'
import MdlFiltros from './CmpModalFiltrosListadoClientes.vue'
import MdlWhatsapp from './MdlWhatsapp.vue'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import clienteIcon from '@/assets/img/usuario.png'
import SistemaSrv from '@/services/SistemaSrv.js'

import Swal from 'sweetalert2'

export default {
  name: 'ListadoClientes',
  components: {
    Layout,
    MdlFiltros, 
    MdlWhatsapp, 
    Paginador, 
    Filtrador 
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      clienteIcon: clienteIcon,
      baseUrl: window.location.origin,
      clientes: [],
      filtros: {
        activos: true,
        suspendidos: false,
        atrasados: false,
        retirados: false,
        recien_registrados: false,
        con_facturas_sin_pagar:false,
        facturas_sin_pagar_desde: 1,
        facturas_sin_pagar_hasta: null,
        renta_mensual_fija: false,
        renta_mensual_variable: false,
        sin_contratos: false,
        control_manual: false,
        control_automatico: false
      },
      dato: '',
      n_pag: 1, // Número de página
      n_cln_pag: parseInt(localStorage.getItem('argusblack.listadoClientes.por_pagina') || 10), // Número de clientes por página
      tc: 0, // Total de clientes
      totPag: 0, // Total de páginas
      usuario: {},
      nClientesEnPag:0,
      nClientesTotales:0,
      columnas: {
        contacto: false,
        foto_cpe: true,
        nombre_cpe: false,
        direccion_ip_cpe: true,
        servicio: true,
      },
      orden: 'nombre_cliente desc',
      foto_cliente: false
    }
  },
  created () {
    var self = this

    // Ejecución de instrucciones iniciales
    if (this.$route.query.dato)       this.dato       = this.$route.query.dato
    if (this.$route.query.n_pag)      this.n_pag      = parseInt(this.$route.query.n_pag)
    if (this.$route.query.n_cln_pag)  this.n_cln_pag  = parseInt(this.$route.query.n_cln_pag)
    if (this.$route.query.filtros)    self.filtros    = JSON.parse(this.$route.query.filtros)
    if (this.$route.query.orden)      self.orden      = this.$route.query.orden

    self.refrescarClientes()
    self.cargarFotoCliente()

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },
  methods: {
    actualizarFiltros(filtros) {
      this.filtros = filtros
      iu.spinner.mostrar('#tblListaClientes')
      this.refrescarClientes()
    },
		actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoClientes.columnas', JSON.stringify(self.columnas))
    },
    actualizarOrden(columna) {
      let self = this
      let index = self.orden.search(columna)

      if ( index == -1 ) {
        self.orden = columna + ' asc'
      } else {
        let ordenamiento = self.orden.substring(index + columna.length + 1 )
        switch(ordenamiento) {
          case 'asc': self.orden = columna+' desc'; break;
          case 'desc': self.orden = ''; break;
        }
      }

      self.refrescarClientes()
    },
    cargarFotoCliente: function() {
      var self = this

      SistemaSrv.ajustes(['foto_cliente']).then(response => {
        self.foto_cliente = response.data.foto_cliente == "0" ? false : true
      })
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoClientes.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },
    cargarPagina: function(n) {
      var self = this
      self.n_pag = n
      self.refrescarClientes({ n_pag: n })
    },
    descargarVCard: function(idCliente) {
      window.open(`${API}/clientes/${idCliente}.vcf?_tk=${this.tk}`,'_blank')
    },
    mostrarMdlWhatsapp(telefonos, nombreCliente) {
      let self = this
      self.$refs.mdlWhatsapp.mostrar(telefonos, nombreCliente)
    },
    mostrarMdlFiltros() {
      let self = this
      self.$refs.mdlFiltros.mostrar()
    },
    obtenerOrden (columna) {
      let self = this
      let index = self.orden.search(columna)
      let ordenamiento = ''

      if ( index == -1 ) ordenamiento = ''
      else ordenamiento = self.orden.substring(index + columna.length + 1 )

      return ordenamiento
    },
    preguntaBorrarCliente: function(idCliente, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Borrar cliente",
        html: '¿Está seguro que desea borrar al cliente <strong>'+nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ClienteSrv.eliminar(idCliente).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarClientes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (error) {
              mensaje = 'No se pudo borrar el cliente'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaMotivoRetiro: function(idCliente, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Motivo de retiro",
        html: 'Escribe el motivo por el cual será retirado el cliente <strong>' + nombre + '</strong>',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a"
      }).then(text => {
        if (text.value) {
          let params = { motivo_retiro: text.value }

          ClienteSrv.retirar(idCliente, params).then(response => {
            swal.fire("Retirado!", "Se retiro correctamente", "success");
            self.refrescarClientes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (error) {
              mensaje = 'No se pudo retirar el cliente'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaRestaurarCliente: function(idCliente, nombre) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Restaurar cliente",
        html: '¿Desea restaurar al cliente <strong>' + nombre + '</strong>?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ClienteSrv.restaurar(idCliente).then(response => {
            swal.fire("Restaurado!", "Se restauró correctamente al cliente", "success");
            self.refrescarClientes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (error) {
              mensaje = 'No se pudo restaurar el cliente'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaRetirarCliente: function(idCliente, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Retiro de cliente",
        html: '¿Desea retirar al cliente <strong>' + nombre + '</strong>?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntaMotivoRetiro(idCliente, nombre)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarClientes(config) {
      var self = this
      var filtros = []
      iu.spinner.mostrar('#tblListaClientes')

      this.$router.replace({
        query: {
          dato: self.dato,
          n_pag: self.n_pag,
          n_cln_pag: self.n_cln_pag,
          filtros: JSON.stringify(self.filtros),
          orden: self.orden 
        }
      }).catch(error => {})

      if (self.filtros.activos) {
        if (self.filtros.suspendidos) filtros.push('suspendidos')
        if (self.filtros.atrasados) filtros.push('atrasados')
        if (self.filtros.recien_registrados) filtros.push('recien_registrados')
      }

      let params = {
        pagina: self.n_pag,
        por_pagina: self.n_cln_pag,
        dato: self.dato,
        activos: self.filtros.activos ? true : false,
        retirados: self.filtros.retirados ? true : false,
        renta_mensual_fija: self.filtros.renta_mensual_fija ? true : false,
        renta_mensual_variable: self.filtros.renta_mensual_variable ? true : false,
        con_facturas_sin_pagar: self.filtros.con_facturas_sin_pagar ? true : false,
        filtros: filtros,
        con_contratos: true,
        con_telefonos: true,
        orden: self.orden,
        sin_contratos: self.filtros.sin_contratos ? true : false,
        control_manual: self.filtros.control_manual ? true : false,
        control_automatico: self.filtros.control_automatico ? true : false
      }

      if (self.filtros.con_facturas_sin_pagar == true) {
        params.facturas_sin_pagar_desde = self.filtros.facturas_sin_pagar_desde || 1
        params.facturas_sin_pagar_hasta = self.filtros.facturas_sin_pagar_hasta || null
      }

      ClienteSrv.clientesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoClientes.por_pagina', self.n_cln_pag)
        let res = response.data
        res.clientes = res.clientes.map(function(cliente){
          cliente['nombre_completo'] = cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno
          return cliente
        })

        self.clientes = res.clientes
        self.tc = res.total
        self.totPag = res.ultima_pagina
        self.nClientesEnPag = res.nClientesEnPag
        self.nClientesTotales = res.total

        if (self.totPag < self.n_pag) {
          self.n_pag = self.totPag
          self.refrescarClientes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (fin => {
        iu.spinner.ocultar('#tblListaClientes')
      })
    },
    verTableroCliente: function(id) {
      var self = this
      self.$router.push({ name: 'tableroCliente', params: { id_cliente: id } })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaClientes')
  }
}
</script>

<style scoped>
.td-seleccionable {
  cursor: pointer;
}
.imgcpes {
  width: 30px;
  height: 30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
.text-gris {
  color: #8C9FB9;
}
.text-truncate {
  width: 420px;
}
</style>
